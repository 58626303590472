<template>
  <div class="intelligence">
    <component :is="curr_view" ></component>
    

  </div>
</template>

<script>
import AX400 from '@/components/ax400-rk200/ax400/index.vue'
export default {
    components:{
      AX400
    },
    data() {
      return {
        lis:[
          {name:'AX400',opt:1,avatar:require('../../../assets/image/ax400_avator.png'),code:'AX400'},
          // {name:'RK200',opt:2,avatar:require('../../../assets/image/rk200_avator.png'),code:'RK200'}
        ],
        curr_view:'AX400'

      }
    },
    methods:{
      
    }
}

</script>

<style lang="less">
  @import '~@/assets/style/chat/ax400.less';
</style>