<template>
  <div class="chat-container">
    <div class="record-container">
        <div class="new-chat" @click="handleTalk('1')">
          <span>新建聊天</span>
          <i class="el-icon-circle-plus-outline"></i>
        </div>
        <!-- 聊天记录 -->
        <div class="record-list">
          <div class="record-item" v-for="v in 20" :key="v">
            <span>今天</span>
            <p>工信部零容忍苹果新标准工信部零容忍苹果新标准</p>
            <p>用户请求帮助</p>
          </div>
          <div class="record-item">
            <span>昨天</span>
            <p>工信部零容忍苹果新标准</p>
            <p>用户请求帮助</p>
          </div>
        </div>
    </div>
    <div class="mind-container">
        <div class="title">
          AX400 {{ statusText }}
          <div class="ax-version">
            <el-select v-model="axVersion" placeholder="请选择版本号">
              <el-option
                v-for="item in versionOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>

        <div class="connect-box">
          <div class="connect-lis" ref="lis">
            <div class="connect-main">
                <div class="chat-time">
                  <span>{{ chat_time }}</span>
                  <span>AX400为您服务</span>
                </div>

                <div class="chat-user" v-for="(v, i) in messageLis" :key="'message' + i">
                  <div class="other-user user-box" v-if="v.type == 'ax400'">
                    <div class="user-avator">
                      <img src="../../../assets/image/ax400_avator.png" alt="" />
                    </div>
                    <div class="user-text" v-if="v.messageTxt">
                      <div v-if="v.is_type">
                        <!--  :contentType="'html'" -->
                        <vue-typed-js
                          :strings="[item]"
                          :typeSpeed="10"
                          :contentType="'html'"
                          v-for="(item,index) in v.messageTag"
                          :key="'typed'+index"
                          @onComplete="doSmth()"
                          @onTypingPaused="endDosmth()"
                          @onStop="stopSmth()"
                          :showCursor="false"
                        >
                          <span :class="[{ typing: v.is_type }]"></span>
                          <!-- <span class="typing"></span> -->
                        </vue-typed-js>
                      </div>
                      <span v-if="!v.is_type" v-html="v.messageTxt"></span>
                    </div>
                  </div>

                  <div class="my-user user-box" v-else>
                    <div class="user-text" v-html="v.messageTxt"></div>
                    <div class="user-avator">
                      <img :src="v.avator" alt="" v-if="v.avator" />
                      <span v-else>{{ lastName(v.name) }}</span>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div class="connect-send">
            <div class="send-main">
              <div class="send-box">
                <el-popover
                  placement="top-start"
                  width="200"
                  trigger="manual"
                  content="不能发送空白信息"
                  v-model="sendVisible">
                  <el-input
                    slot="reference"
                    class="connect-msg"
                    :disabled="!is_end"
                    v-model="messageTxt"
                    placeholder="请输入内容,可用Enter发送"
                    @keydown.enter.native="handleKeyCode($event)"
                  >
                  </el-input>
                </el-popover>
                
                <div class="send-btn">
                  <el-button type="primary" :disabled="!is_end" @click="handleSendText">发送</el-button>
                </div>
              </div>
              <div class="send-switch">
                  <el-switch
                    v-model="sendSwitch"
                    active-text="单句提问"
                    active-color="#13ce66"
                    inactive-text="">
                  </el-switch>
              </div>
            </div>
            
            <p>AX400可能会犯错误，请考虑核实重要信息。</p>
            
            <!-- 新开话题，继续此话题 -->
            <!-- <div class="subject-talk" v-if="!is_end">
              <div class="btn new-btn" @click="handleTalk('1')">新开话题</div>
              <div class="btn continue-btn" @click="handleTalk('0')">继续此话题</div>
            </div> -->
          </div>
        </div>
      </div>
  </div>

  
</template>
  <script>
import { getAX400Start, postAX400Ask, postAX400Receive } from '@/service/help';
import { dateFormat } from '@/utils/utils';
import MarkdownIt from 'markdown-it'
import mdKatex from '@traptitech/markdown-it-katex'
import hljs from 'highlight.js';
import Clipboard from 'clipboard'
import { Message } from 'element-ui';

let codeTagId = 0 // 代码块id
function highlightBlock(str, lang) {
  return `<pre class="pre-box">
              <div class="code-header">
                <span >${lang}</span>
                <span id="copy-btn${codeTagId}" data-clipboard-target="#code${codeTagId}" onclick="getCopy('copy-btn${codeTagId}')">复制代码</span>
              </div>
              <code class="hljs ${lang}" id="code${codeTagId}">${str}</code>
          </pre>
          `
}

export default {
  data() {
    return {
      ns: '1', // 0继续 1新话题
      chat_time: dateFormat('hh:mm'),
      messageLis: [],
      messageTxt: '',
      is_end: true,
      message_end: true,
      handleFlag: false,
      s: '',
      start: '',
      mdi:'',
      codeLanguage:'',
      isContinueReceive:false, // 是否继续接收代码信息
      codeStr:'', // 接收到的代码块字符串
      sendVisible:false, // 空白信息弹窗，
      sendSwitch:true, // 提问开关
      axVersion:'AX400版本4.0', // ax400版本号
      versionOptions:[
        {label:'AX400版本2.0',value:'AX400版本2.0'},
        {label:'AX400版本3.0',value:'AX400版本3.0'},
        {label:'AX400版本4.0',value:'AX400版本4.0'}
      ]
    };
  },
  components: {},
  created() {
    console.log(this.userInfo, 'user');
    const that = this
    
    // this.mdi.use(mdKatex, { blockClass: 'katexmath-block rounded-md p-[10px]', errorColor: ' #cc0000' })
    // getAX400Start()
    this.messageLis = sessionStorage.getItem('messageLis') ? JSON.parse(sessionStorage.getItem('messageLis')) : [];
    if (this.messageLis.length > 0) {
      this.messageLis.forEach(v => (v.is_type = false));
      codeTagId = this.messageLis.length
    }
    this.setLisScrollTop();


    this.mdi = new MarkdownIt({
      html: true,
      breaks: true,
      linkify: true,
      typographer: true,
      highlight(code, language) {
        console.log(language,'====lalalalalal=========')
        that.codeLanguage = language ? language : ''
        codeTagId ++
        // const validLang = !!(language && hljs.getLanguage(language))
        // if (validLang) {
        //   const lang = language ?? ''
        //   return highlightBlock(hljs.highlight(lang, code, true).value, lang)
        // }
        return highlightBlock(hljs.highlightAuto(code).value, that.codeLanguage)
      }
    })


    window.getCopy = function(btnId){
          console.log(btnId)
          let clipboard = new Clipboard("#"+btnId)
          // 复制成功
          clipboard.on("success", (e) => {
            console.log(e, '复制成功');
            Message({
                type: 'success',
                message: '复制成功!',
            });
            clipboard.destroy();
          });
          // //复制失败
          clipboard.on("error", (e) => {
            console.log(e, '复制失败');
            Message({
                type: 'error',
                message: '复制失败',
            });
            clipboard.destroy();
          });

    }

    // console.log(this.messageLis, 'that.messageLis===');
    // console.log(/^```.*```$/gm.test('```javascript  ```\n\n'));
  },
  watch: {
    is_end(newVal, oldVal) {
      console.log('啦啦啦啦啦啦', newVal, oldVal);
    },
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
    statusText() {
      return !this.message_end ? '正在输入...' : '';
    },
  },
  methods: {
    
    countOccurrences(str, charToCount) {
      // 使用正则表达式，g标志表示全局匹配
      const regex = new RegExp(charToCount, 'g');
      // 使用match方法计算匹配到的个数
      const matches = str.match(regex);
      return matches ? matches.length : 0;
    },
    stopSmth() {
      console.log('停下来之后');
    },
    endDosmth() {
      // 打字已停止
      console.log('打字已停止');
    },
    doSmth() {
      // 打字完成之后
      console.log('打字完成之后');
      if(!this.isContinueReceive){
        this.ReceiveText(this.s, this.start);
      }
      
    },
    initAns(data) {
      let send_data = ''
      this.codeStr += data
      // data = data.replace(/&/g, '&amp;');
      // data = data.replace(/</g, '&lt;');
      // data = data.replace(/>/g, '&gt;');
      // data = data.replace(/`/g, '&#96;');
      // data = data.replace(/\n/g, '<br>');
      
      if(this.countOccurrences(this.codeStr,'```')%2 !== 0 ){ // 奇数 继续接收信息，直接出现偶数再呈现到html上
        this.isContinueReceive = true
        
      }else{ // 偶数 说明是完整的代码块
        send_data = this.mdi.render(this.codeStr)
        this.codeStr = '' // 完整代码块需要 清空缓存  可能多个代码块，或者包含其他文本
        this.isContinueReceive = false
      }
      
      return send_data;
    },
    resetTalk() {
      // 开启新话题
      this.chat_time = dateFormat('hh:mm');
      this.messageLis = [];
      sessionStorage.setItem('messageLis', '');
    },
    handleTalk(ns) {
      this.ns = ns;
      this.is_end = true;
      if (this.ns == '1') this.resetTalk();
    },
    handleKeyCode(e) {
      console.log(e, '==event=');
      if (e.ctrlKey && e.keyCode == 13) {
        //用户点击了ctrl+enter触发
        this.messageTxt += '\n';
      } else {
        e.preventDefault(); // 阻止浏览器默认换行操作
        //用户点击了enter触发
        this.handleSendText();
      }
    },
    handleSendText() {
      console.log(this.messageTxt, 'this.messageTxt');
      const that = this
      if(this.sendVisible) return
      if(!this.messageTxt){
        this.sendVisible = true
        let timer = setTimeout(()=>{
          clearTimeout(timer)
          that.sendVisible = false
        },2000)
        return
      }
      if (this.handleFlag) return;
      this.handleFlag = true;
      postAX400Ask({
        ques: this.messageTxt,
        ns: this.ns,
      }).then(res => {
        console.log(res);
        this.handleFlag = false;
        let { success, data } = res.data;
        if (success) {
          this.messageLis.push({
            type: 'my',
            messageTxt: this.messageTxt,
            name: this.userInfo.name,
            avator:
              this.userInfo.avatar == 'https://oss.eai9.com/210120/5a2296c509b67333f6a81bee7cc60b13.png'
                ? ''
                : this.userInfo.avatar,
          });
          this.messageTxt = '';
          this.is_end = false;
          this.message_end = false;
          this.setLisScrollTop();
          this.ReceiveText(data);
        }
      });
    },
    ReceiveText(s, start = '0') {
      const that = this;
      let timer = setTimeout(() => {
        postAX400Receive({
          s: s,
          start: start,
        }).then(res => {
          console.log(res);
          let { success, data } = res.data;
          if (success) {
            that.message_end = data.is_end;
            that.s = s;
            that.start = data.start;

            if (data.ans) {
              let ans = that.initAns(data.ans);
              if(that.isContinueReceive){ // 直到有完整的代码块再到下一步
                that.ReceiveText(s,data.start)
                return
              }
              if (that.messageLis[that.messageLis.length - 1].type == 'my') {
                that.messageLis.push({
                  type: 'ax400',
                  messageTxt: ans,
                  messageTag: [ans],
                  is_type: true,
                });
              } else {
                that.messageLis[that.messageLis.length - 1].messageTxt =
                  that.messageLis[that.messageLis.length - 1].messageTxt + ans;
                that.messageLis[that.messageLis.length - 1].messageTag.push(ans);
              }
              //   that.messageLis[that.messageLis.length - 1].messageTxt = that.messageLis[that.messageLis.length - 1].messageTxt.replace(/^```.*```$/gm,`&lt;highlightjs  code=" ${that.messageLis[that.messageLis.length - 1].messageTxt} " &gt;&lt;&#47highlightjs&gt;`)
              console.log(
                that.messageLis[that.messageLis.length - 1].messageTxt,that.messageLis[that.messageLis.length - 1].messageTag,
                '====that.messageLis[that.messageLis.length - 1].messageTxt==',
              );
              sessionStorage.setItem('messageLis', JSON.stringify(that.messageLis));

              if (data.is_end) {
                clearTimeout(timer);
                console.log('这里执行了吗');
              }
            } else {
              if (data.is_end) {
                clearTimeout(timer);
                console.log('这里执行了吗');
              } else {
                this.ReceiveText(s, data.start);
              }
            }

            // 最后接收数据判断是否结束
            if(data.is_end){ // 结束后默认继续话题
              this.handleTalk('0')
            }
            this.setLisScrollTop();
          }
        });
      }, 500);
    },
    setLisScrollTop() {
      this.$nextTick(() => {
        this.$refs.lis.scrollTop = this.$refs.lis.scrollHeight;
      });
    },
  },
};
</script>
<style lang="less">
@import '~@/assets/style/chat/ax400.less';
</style>